/*
 * Global Variables
 */

// Google font import
@import url('https://fonts.googleapis.com/css2?&family=Fira+Sans&family=Unica+One&family=Jaldi&display=swap');

// Custom variables

$breakpoint: 500px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:                     1rem !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;
$border-width:               .0625rem !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:     "Fira Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-display:        "Fira Sans";
$font-family-serif:          "Fira Sans", "Times New Roman", Times, serif !default;
$font-family-monospace:      "Courier New", monospace !default;
$font-family-base:           $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root:             15px !default;

$font-size-base:             $font-size-root !default;
$font-size-lg:               $font-size-root * 1.25 !default;
$font-size-sm:               $font-size-root * 0.875 !default;
$font-size-xs:               $font-size-root * 0.75 !default;

$font-size-h1:               2rem !default;
$font-size-h2:               1.75rem !default;
$font-size-h3:               1.5rem !default;
$font-size-h4:               1.2rem !default;
$font-size-h5:               1.05rem !default;
$font-size-h6:               1rem !default;

$base-line-height:            1.4;
$base-space-unit:             1.5em;

$hr-border-color:             rgba(0,0,0,.1) !default;
$hr-border-width:             $border-width;

$boldweight: 600;

//
// Colors
//

$text-color:       rgba(0, 0, 0, 0.87) !default;
$background-color: rgb(247, 245, 243);
$section-background-color: rgb(247, 245, 243);

$white:            rgb(254, 252, 250);
$primary:          rgb(50, 100, 150);
// $primary:          rgb(34, 111, 98);
$secondary:        rgb(50, 100, 150);
// $secondary:        rgb(34, 111, 98);
$success:          #65e892;
$danger:           #f83e4b;
$warning:          #F5A623;
$info:             #09ABF6;

// Tables
$table-border-color: #b6b6b6 !default;
$table-striped-color: #f2f2f2 !default;

// Grid Variables
$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

// Media Query Ranges
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$larger-screen: 1600px !default;
