// Post listing
.post-listing {
  background-color: $white;
  position: absolute;
  top: 520px;
  z-index: 233;
  width: 100%;
  padding: 20px 20px 0;
  -webkit-transform: translate3d(0,0,1px);
  transform: translate3d(0,0,1px);
  min-height: 100%;
  @include media-query($small-screen) {
    padding: 30px 30px 0;
    width: 100%;
  }
  @include media-query($medium-screen) {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    position: initial;
    top: 0;
  }
}

.image-credit {
  float: right;
  font-weight: bold;
  font-style: italic;
  color: $info;
  font-size: 13px;
  padding-right: 20px;
}

// Post Section
section.post {
	margin-bottom: 15px;
  padding: 1px 15px 1px 12px;
  background-color: $section-background-color;
}

section {
  border-radius: 10px;
  -moz-border-radius: 10px;
}

.post-description p {
  margin: 0px;
  font-style: italic;
  color: grey;
}

.post-header h4 {
  margin-bottom: 0px
}

.post-header h5 {
  margin: 0 0 3px 0
}

.paper-header {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.downloadat {
  color: $secondary;
}

// Back home button

.back-home a {
  font-size: 13px;
  font-weight: bold;
  color: $info;
  border: 1px solid lighten($text-color, 45%);
  border-radius: 0.4em;
  padding: 0.5em 1em;
}

// Post coauthors
.post-coauthors {
  font-weight: normal;
  font-size: 90%;
}

.date {
  font-weight: normal;
  font-size: 90%;
  color: grey;
  float: right;
  padding-top: 0px;
}

.only-small {
  font-size: 0%;
  @include media-query($small-screen) {
    font-size: 90%;
  }
}

.post-journal {
  font-weight: normal;
  // font-family: $font-family-serif;
  margin-top: 2px;
  margin-bottom: 5px;
}

// Post title
.paper-title {
  margin-bottom: 4px;
}

.post-title-link {
	color: $primary;
	font-size: $font-size-root;
	// margin-left: 10px;
	&:hover,
	&:focus {
		color: lighten($primary, 24%);
	}
}

.journal {
  color: $text-color;
  font-style: normal;
  font-family: $font-family-serif;
  .jlink {
      &:hover,
      &:focus {
        color: lighten($text-color, 34%);
      }
  }
}



// Post meta
.post-meta {
  font-size: 13px;
  font-weight: bold;
	.post-date {
		color: $secondary;
	}
	.post-author {
		text-transform: uppercase;
		color: $warning;
	}
  .post-cat {
		text-transform: uppercase;
		color: $warning;
	}
  .read-time {
    color: $primary;
  }
}

// Read more buttons

a.read-more {
  padding: 5px 8px;
}

.abstract {
  background-color: $section-background-color;
  margin: 5px;
  // font-family: $font-family-serif;
}

// Collapse

.button {
  background-color: $section-background-color;
  border: 0;
  color: $secondary;
  padding: 2px 3px 2px 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  outline: none;
  &:hover {
    // background-color: $secondary;
    // color: $white;
    color: lighten($primary, 14%);
  }
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: $section-background-color;
  color: $secondary;
  cursor: pointer;
  padding: 2px 3px 2px 2px;
  // width: 20em;
  margin: 0px;
  border: none;
  text-align: left;
  outline: none;
  &:hover {
    color: lighten($primary, 14%);
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
// .active, .collapsible:hover {
//   background-color: #ccc;
// }

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  background-color: $white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

// .collapsible::after {
//   content: '\02795'; /* Unicode character for "plus" sign (+) */
//   font-size: 13px;
//   color: white;
//   float: right;
//   margin-left: 5px;
// }

// .active::after {
//   content: "\2796"; /* Unicode character for "minus" sign (-) */
// }

// Post content

#post {
  padding-bottom: 50px;
  .post-header {
    margin: 0 auto 50px;
  }
}

#post h1 {
  margin: 0.5em 0 1em;
}
#post h2 {
  margin: 2em 0 0.8em;
  padding-bottom: 0.7em;
  border-bottom: 1px solid #ddd;
}
#post h3 {
  margin: 1.75em 0 1.2em;
  position: relative;
}

#page {
  padding-bottom: 50px;
  min-height: 70vh;
  .page-header {
    margin: 0 auto 50px;
  }
}

.post-navigation {
  background-color: #f6f6f6;
  @include box-shadow;
}
.leonids-icon {
  margin: 0 auto;
  padding: 20px 30px;
  text-align: center;
  color: $warning;
  font-size: 16px;
  line-height: 16px;
}
.post-nav-prev,
.post-nav-next {
  min-height: 10px;
  width: 100%;
}
.post-nav-next {
  text-align: right;
}
.post-nav-btn {
  width: 100%;
  padding: 20px 30px;
  .post-nav-text {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
  }
  .table-cell {
    vertical-align: middle;
  }
}
.post-image-feature {
  margin-bottom: 40px;
  .feature-image {
    width: 100%;
    max-height: 500px;
  }
}

// Shares Buttons
.share-buttons {
  padding-top: 1em;
  border-top: 1px solid #ddd;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      margin-right: 0.5em;
      span {
        display: none;
        @include media-query($small-screen) {
          display: inline;
          margin: 0.5em;
        }
      }
    }
  }
}
.twitter {
  background-color: #77bcf1;
  color: $white;
  border-color: #77bcf1;
  &:visited {
    color: $white;
    border-color: #77bcf1;
  }
  &:hover {
    background-color: $white;
    color: #77bcf1;
    border-color: #77bcf1;
  }
}
.facebook {
  background-color: #627aac;
  color: $white;
  border-color: #627aac;
  &:visited {
    color: $white;
    border-color: #627aac;
  }
  &:hover {
    background-color: $white;
    color: #627aac;
    border-color: #627aac;
  }
}
.google-plus {
  background-color: #e36f60;
  color: $white;
  border-color: #e36f60;
  &:visited {
    color: $white;
    border-color: #e36f60;
  }
  &:hover {
    background-color: $white;
    color: #e36f60;
    border-color: #e36f60;
  }
}
.hacker-news {
  background-color: #f60;
  color: $white;
  border-color: #f60;
  &:visited {
    color: $white;
    border-color: #f60;
  }
  &:hover {
    background-color: $white;
    color: #f60;
    border-color: #f60;
  }
}
.reddit {
  background-color: #5f99cf;
  color: $white;
  border-color: #5f99cf;
  &:visited {
    color: $white;
    border-color: #5f99cf;
  }
  &:hover {
    background-color: $white;
    color: #5f99cf;
    border-color: #5f99cf;
  }
}
